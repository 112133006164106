import { createSlice } from '@reduxjs/toolkit';

import CanvasTissue from 'src/services/canvas-v4/canvas-tissues/canvas-tissue';

// ----------------------------------------------------------------------

export type StateType = {
  isInitialized: boolean;
  totalAnnotations: number;
  tissues: CanvasTissue[];
  activeTissueUUID: CanvasTissue['uuid'] | undefined;
};

const initialState: StateType = {
  isInitialized: false,
  totalAnnotations: 0,
  tissues: [],
  activeTissueUUID: undefined,
};

// ----------------------------------------------------------------------

export const sliceCanvasService = createSlice({
  name: 'canvasService',
  initialState,
  reducers: {
    setIsInitialized: (state, { payload }: { payload: boolean }) => {
      state.isInitialized = payload;
    },
    addTissue: (state, { payload }: { payload: CanvasTissue }) => {
      state.tissues.push(payload);
    },
    removeTissue: (state, { payload }: { payload: CanvasTissue['uuid'] }) => {
      state.tissues = state.tissues.filter((tissue) => tissue.uuid !== payload);
      if (state.activeTissueUUID === payload) {
        state.activeTissueUUID = undefined;
      }
    },
    setActiveTissue: (state, { payload }: { payload: StateType['activeTissueUUID'] }) => {
      state.activeTissueUUID = payload;
    },
    updateTotalAnnotations: (state, { payload }: { payload: number }) => {
      state.totalAnnotations = payload;
    },
    resetCanvasService: (state) => {
      state.tissues = [];
      state.totalAnnotations = 0;
      state.activeTissueUUID = undefined;
    },
  },
  selectors: {
    getActiveTissue: (state) => state.tissues.find((tissue) => tissue.uuid === state.activeTissueUUID),
  },
  extraReducers: (builder) => {
    //
  },
});

export const {
  setIsInitialized,
  addTissue,
  removeTissue,
  setActiveTissue,
  updateTotalAnnotations,
  resetCanvasService,
} = sliceCanvasService.actions;

export const selectorsCanvasService = sliceCanvasService.selectors;

export default sliceCanvasService.reducer;
