import { Feature, GeometryTypes } from '@turf/helpers';

// ----------------------------------------------------------------------

export enum TissueType {
  Tissue = 'tissue',
  Unassigned = 'unassigned',
  ROI = 'roi',
}

export const UNIQUE_TISSUE_TYPES: TissueType[] = [TissueType.ROI, TissueType.Unassigned];

export type ITissue = {
  id: number;
  color: string;
  title: string;
  subtitle?: string;
  parent_id?: string;
  type: TissueType;
  annotations_count: number;
  annotations_percent: number;
};

export type IAnnotation = {
  id: number;
  created_at: string;
  shape_type: GeometryTypes;
  top_left_coordinate_x: number;
  top_left_coordinate_y: number;
  geoJSON: Feature;
};

// ----------------------------------------------------------------------

export enum TissueStatsType {
  Area = 'area',
  Count = 'annotations_count',
  AreaRatio = 'area_ratio',
  CountRatio = 'annotations_count_ratio',
}

export enum TissueMeasurementUnitType {
  MM2 = 'mm2',
  Pixel2 = 'pixel2',
}

export type TissueStatAreaType = {
  type: TissueStatsType.Area;
  unit: TissueMeasurementUnitType;
};

export type TissueStat = { value: number } & (
  | TissueStatAreaType
  | {
      type: TissueStatsType.Count;
    }
  | {
      type: TissueStatsType.CountRatio | TissueStatsType.AreaRatio;
      metadata: {
        denominator_class: ITissue;
      };
    }
);

export type TissueStatsList = TissueStat[];
